<template>
  <div>
    <el-card shadow="always">
      <div class="top_label">
        <el-tabs v-model="searchForm.order_status" @tab-click="handleClick">
          <el-tab-pane label="全部" name="0"></el-tab-pane>
          <el-tab-pane label="待付款" name="1"></el-tab-pane>
          <el-tab-pane label="待发货" name="2"></el-tab-pane>
          <el-tab-pane label="运输中" name="4"></el-tab-pane>
          <el-tab-pane label="待确认" name="6"></el-tab-pane>
          <el-tab-pane label="待评价" name="7"></el-tab-pane>
        </el-tabs>
      </div>
      <el-table ref="order_info" fit h :data="orderData" tooltip-effect="dark" style="width: 100%" v-loading="loading">
        <el-table-column prop="product_title" label="商品标题" min-width="80"> </el-table-column>
        <el-table-column prop="product_cover_img" label="商品封面" min-width="50">
          <template slot-scope="scope">
            <el-image :src="scope.row.product_cover_img" fit="contain" class="cover_img"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="product_price" label="单价" min-width="30"> </el-table-column>
        <el-table-column prop="buy_count" label="数量" min-width="30"> </el-table-column>
        <el-table-column prop="total_price" label="总价" min-width="30"> </el-table-column>
        <el-table-column prop="order_status" label="订单状态" min-width="30"> </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.need_display"
              size="mini"
              type="danger"
              @click="
                centerDialogVisible = true
                orderOperation(scope.$index, scope.row)
              "
              >{{ scope.row.operation }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="page_size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          small
        >
        </el-pagination>
      </div>
    </el-card>
    <!--发货弹窗-->
    <div>
      <el-dialog title="确认发货" :visible.sync="dialogDeliverGoodsVisible" width="25%" center>
        <el-form ref="elForm" :model="form" label-width="80px" :rules="rules">
          <el-form-item label="物流公司" prop="logistics">
            <el-select size="mini" v-model="form.logistics">
              <el-option v-for="item in logistics_list" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="物流单号" prop="logistics_no">
            <el-input size="mini" v-model="form.logistics_no"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogDeliverGoodsVisible = false">取 消</el-button>
          <el-button type="primary" @click="deliverGoods()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderSell',
  data() {
    return {
      rules: {
        logistics: [
          {
            required: true,
            message: '物流公司不能问空',
            trigger: 'blur'
          }
        ],
        logistics_no: [
          {
            required: true,
            message: '物流单号不能问空',
            trigger: 'blur'
          }
        ]
      },
      form: {
        logistics: '',
        logistics_no: '',
        order_no: ''
      },
      logistics_list: [
        {
          value: '顺丰速运',
          label: '顺丰速运'
        },
        {
          value: '申通快递',
          label: '申通快递'
        },
        {
          value: '圆通速递',
          label: '圆通速递'
        },
        {
          value: '韵达速递',
          label: '韵达速递'
        },
        {
          value: '中国邮政',
          label: '中国邮政'
        },
        {
          value: '中通速递',
          label: '中通速递'
        }
      ],
      dialogDeliverGoodsVisible: false,
      order_no: '',
      total: 0,
      currentPage: 1,
      page_size: 10,
      loading: false,
      orderData: [],
      searchForm: {
        order_type: 2,
        order_status: '0'
      }
    }
  },
  created() {
    this.getOrderInfo()
  },
  methods: {
    deliverGoods() {
      this.$refs['elForm'].validate(valid => {
        if (valid) {
          this.form.order_no = this.order_no
          this.$api.order.deliverGoods(this.form).then(res => {
            if (res.data.code === 200) {
              this.$message.success({
                message: res.data.msg,
                center: true,
                duration: 2000
              })
              this.getOrderInfo()
            } else {
              this.$message.error({
                message: res.data.msg,
                center: true,
                duration: 5000
              })
            }
          })
          this.dialogDeliverGoodsVisible = false
        }
      })
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.searchProduct()
    },
    handleSizeChange(val) {
      this.page_size = val
      this.searchProduct()
    },
    orderOperation(index, row) {
      this.order_no = ''
      this.form = {
        logistics: '',
        logistics_no: '',
        order_no: ''
      }
      if (row.operation === '去发货') {
        this.dialogDeliverGoodsVisible = true
        this.order_no = row.order_no
      } else {
        console.log('失败')
      }
    },
    handleClick() {
      if (this.searchForm.order_status === '0') {
        delete this.searchForm.order_status
      }
      this.getOrderInfo()
    },
    getOrderInfo() {
      this.loading = true
      this.$api.order.getOrderInfo(this.searchForm).then(res => {
        let resData = res.data.data.list
        this.total = res.data.data.total
        this.loading = false
        for (const r in resData) {
          if (resData[r].order_status === 1) {
            resData[r]['order_status'] = '待支付'
            resData[r]['need_display'] = false
          } else if (resData[r].order_status === 2) {
            resData[r]['operation'] = '去发货'
            resData[r]['order_status'] = '待发货'
            resData[r]['need_display'] = true
          } else if (resData[r].order_status === 3) {
            resData[r]['order_status'] = '已发货'
            resData[r]['need_display'] = false
          } else if (resData[r].order_status === 4) {
            resData[r]['order_status'] = '运输中'
            resData[r]['need_display'] = false
          } else if (resData[r].order_status === 5) {
            resData[r]['order_status'] = '已送达'
            resData[r]['need_display'] = false
          } else if (resData[r].order_status === 6) {
            resData[r]['order_status'] = '待确认'
            resData[r]['need_display'] = false
          } else if (resData[r].order_status === 7) {
            resData[r]['order_status'] = '待评价'
            resData[r]['need_display'] = false
          } else if (resData[r].order_status === 8) {
            resData[r]['order_status'] = '已完成'
            resData[r]['need_display'] = false
          } else {
            resData[r]['need_display'] = false
          }
        }
        this.orderData = resData
      })
    }
  }
}
</script>

<style scoped>
.cover_img {
  width: 90px;
  height: 90px;
}
.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.top_label {
  margin-bottom: 10px;
}
.pay_dialog {
  display: flex;
  flex-direction: column;
}
.evaluation {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
</style>
